<template lang="pug">
.main-wrapper.centro-lucro-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "centro-lucro" }'> Centro de Lucro</router-link> /
                        <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } centro de lucro`")
                    .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nm_centro_lucro.$error }")
                            label.form-label Descrição:
                            InputText(type='text' v-model='$v.model.nm_centro_lucro.$model')
                            .feedback--errors(v-if='submitted && $v.model.nm_centro_lucro.$error')
                                .form-message--error(v-if="!$v.model.nm_centro_lucro.minLength") <b>Descrição</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.nm_centro_lucro.required") <b>Descrição</b> é obrigatório.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_setor.$error }")
                            label.form-label Setor:
                            MultiSelect(
                                v-model="model.cd_setor"
                                :options="options.setoresOption"
                                optionLabel="text"
                                optionValue="value"
                                dataKey="value"
                                filter
                            )
                                template(#option='props')
                                    span <b> {{ props.option.text }} </b>
                                    br
                                    span.ml-4 {{ props.option.textAlt }}
                                    
                            .feedback--errors(v-if='submitted && $v.model.cd_setor.$error')
                                .form-message--error(v-if="!$v.model.cd_setor.required") <b>Setor</b> é obrigatório.

                        .p-col-12.mt-2(v-if="model.id")
                            .p-grid
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                        .p-col-12.mt-2
                            Button(label='Salvar' type="submit")
</template>

<style lang="scss">
    .centro-lucro-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'

    import { CentroLucro, UnidadesMedclub } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    import moment from "moment"
    import axios from "axios"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
           
            this.getSetor()
            let id = null
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id) {
                CentroLucro.find(id).then(response => {
                if (response.status == 200) {
                    let keys = Object.keys(this.model)
                    
                    keys.forEach(key => this.model[key] = response.data[key])

                    this.model.id = id
                    this.model.cd_setor = this.model.cd_setor.map(item => item.id)
                    
                    this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                    this.model.nm_usuario_cri = response.data.nm_usuario_cri
                    if (response.data.dt_atualizado) {
                        this.model.nm_usuario_edi = response.data.nm_usuario_edi
                        this.model.dt_atualizado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                    }
                }
                this.waiting = false
                })
            } else this.waiting = false     
        },
        data () {
            return {
                model: {
                    nm_centro_lucro: '',
                    cd_setor: [],
                },
                options: {
                    setoresOption: [],
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    nm_centro_lucro: { required, minLength: minLength(2) },
                    cd_setor: { required }
                }
            }
            
            return v
        },
        methods: {
            getSetor() {
				if (this.cancelToken) this.cancelToken.cancel()
                this.cancelToken = axios.CancelToken.source()

                return UnidadesMedclub.findAllSetor(this.cancelToken.token).then(response => {
                    if (!response) return
                    if (response.status === 200) {
                        response.data.forEach(e => {
                            this.options.setoresOption.push({text: e.nm_setor, textAlt: e.nm_unidade, value: e.id})
                        })
                    } else {
                        this.$toast.error("Houve um problema ao carregar os setores.", { duration: 3000 });
                    }
                    this.cancelToken = null
                })
            },
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                
                this.waitingForm = true
                
                CentroLucro.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'centro-lucro' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })
            }
         }
      }
</script>